import axios from 'axios'
import config from '../config'

const HTTP = axios.create({
	baseURL: config.api_url,
	headers: {
		'Content-Type': 'application/json',
	},
})
export default HTTP
