<template>
  <div id="app">
    <router-view></router-view>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
export default {
  methods: {
    async getUser() {
      const { data:res } = await AuthService.me()
      if (res.success) {
        await this.$store.dispatch('setUser', {
          user: res.data,
          session: true
        })
      }
    }
  },
  created() {
    this.getUser()
	}
}
</script>

<style>

* {
  font-family: 'Ubuntu', sans-serif;
}

.logo {
  width: 150px;
  margin-bottom: 10px
}

.logo_sidebar {
  width: 100px;
}

.vs-sidebar {
  background: #10104F !important
}

.vs-button {
  outline: none !important;
}

.con-vs-popup .vs-popup {
  width: 800px !important;
}

.vs-sidebar--item a:hover {
  color: #DEDC00 !important
}

.vs-sidebar-primary .vs-sidebar-item-active a {
  color: #DEDC00 !important
}

.vs-sidebar.vs-sidebar-parent {
  height: 100vh !important;
}

.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
  margin-top: 5%
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #10104F;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
