<template>
  <div style="margin-top: 5%" id="div-with-loading" class="vs-con-loading__container">
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <img class="logo" src="@/assets/logo.png" alt="">
        <vs-card vs-align="center">
          <div slot="header">
            <h4>
              Iniciar sesión
            </h4>
          </div>
          <div style="width: 90%" class="m-auto d-flex justify-content-center">
            <div>
              <vs-input name="email" data-vv-as="Email" v-validate="'required|email'" label="Email" v-model="user.email"/>
              <span v-show="errors.has('email')" class="text-danger mt-2">
                {{ errors.first("email") }}
              </span>
              <hr>
              <vs-input type="password" name="password" data-vv-as="Contraseña" v-validate="'required'" label="Password" v-model="user.password"/>
              <span v-show="errors.has('password')" class="w-100 text-danger mt-2">
                {{ errors.first("password") }}
              </span>
            </div>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container"
                @click="login" type="gradient" color="primary">Entrar</vs-button>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  import AuthService from '@/services/auth.service'
	export default {
    data() {
      return {
        user: {
          email: 'admin@tagstore.com',
          password: '123456'
        }
      }
    },
    methods: {
      login() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            try {
              this.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6
              })
              const { data:res } = await AuthService.login(this.user)

              if (res.success) {
                await this.$store.dispatch('setUser', {
                  user: res.data.user,
                  session: true
                })
                localStorage.setItem('token_tag', res.data.token_tag)
                setTimeout(() => {
                  this.$router.push('/admin/home')
                }, 1000)
              } else {
                this.$vs.loading.close('#div-with-loading > .con-vs-loading')
                this.$swal(res.error, '', 'warning');
              }
            } catch (e) {
              this.$vs.loading.close('#div-with-loading > .con-vs-loading')
              this.$swal('Ha ocurrido un error interno', '', 'warning');
            }
          }
        })
      }
    }
	}
</script>
