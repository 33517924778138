import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Layout,
    children: [
      {
        path: '/admin/home',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/admin/banners',
        name: 'Banners',
        component: () => import('../views/Banners.vue')
      },
      {
        path: '/admin/about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: '/admin/qr-codes',
        name: 'Qr',
        component: () => import('../views/Qr.vue')
      },
      {
        path: '/admin/orders',
        name: 'Orders',
        component: () => import('../views/Orders.vue')
      },
      {
        path: '/admin/products',
        name: 'Products',
        component: () => import('../views/Products.vue')
      },
      {
        path: '/admin/product-categories',
        name: 'ProductCategories',
        component: () => import('../views/ProductCategories.vue')
      },
      {
        path: '/admin/posts',
        name: 'Posts',
        component: () => import('../views/Posts.vue')
      },
      {
        path: '/admin/users',
        name: 'Users',
        component: () => import('../views/Users.vue')
      },
      {
        path: '/admin/attributes',
        name: 'Attributes',
        component: () => import('../views/Attributes.vue')
      },
      {
        path: '/admin/policies',
        name: 'Policies',
        component: () => import('../views/Policies.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
