import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: ''
    },
    is_auth: false
  },
  mutations: {
    SET_CURRENT_USER(state, data) {
			state.user = data.user
			state.is_auth = data.session
		}
  },
  actions: {
    setUser({ commit }, data) {
			commit('SET_CURRENT_USER', data)
		}
  },
  modules: {
  }
})
