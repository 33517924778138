import api from '@/api'

class AuthService {
  static login(data) {
    return api({
      url: 'login',
      data: data,
      method: 'post'
    })
  }

  static logout() {
    return api({
      url: 'logout',
      method: 'POST'
    })
  }

  static me() {
		return api({
			url: 'me',
			method: 'POST'
		})
	}
}

export default AuthService
