import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es.js";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { GooglePlacesAutocomplete } from 'vue-better-google-places-autocomplete'

import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('google-places-autocomplete', GooglePlacesAutocomplete)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

const options = {
  confirmButtonColor: '#10104F',
  cancelButtonColor: '#DEDC00',
};

Vue.use(VueSweetalert2, options);

Vue.use(Vuesax, {
  theme:{
    colors:{
      primary: '#D2D63E',
      success:'#10104F',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }
  }
})

Vue.use(VeeValidate);
Validator.localize("es", es);

Vue.config.productionTip = false

import interceptors from '@/api/interceptors'

interceptors()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
