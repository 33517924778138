import api from '../api'
import router from '../router'

export default function setup() {
	//Interceptor request
	api.interceptors.request.use(
		function(config) {
      const token = localStorage.getItem('token_tag')
			if (token) {
				config.headers.Authorization = `Bearer ${token}`
      }
			return config
		},
		function(err) {
			return Promise.reject(err)
		}
	)

	//Interceptor response
	api.interceptors.response.use(
		function(response) {
			// Do something with response data
			return response
		},
		function(error) {
			if (error.response.status === 401) {
        localStorage.removeItem('token_tag')
        if (router.name != 'Login') {
          router.push('/')
        }
      }
      if (error.response.status === 402) {
        console.log(error.response)
      }
		}
	)
}
