<template>
  <div id="parentx">
    <vs-sidebar default-index="1" hidden-background color="primary" class="sidebarx" spacer v-model="active">

      <div class="header-sidebar" slot="header">
        <img class="logo_sidebar" src="@/assets/logo_amarillo.png" alt="">
      </div>

      <vs-sidebar-item style="color: #DEDC00" :to="item.href" v-for="(item, index) in menu_items" :key="index" :index="index + 1" :icon="item.icon">
        {{item.text}}
      </vs-sidebar-item>

      <div class="footer-sidebar" slot="footer">
        <vs-button :disabled="inactive_bottom" @click="logout" icon="reply" color="danger" type="flat">Salir</vs-button>
      </div>

    </vs-sidebar>
  </div>
</template>

<script>
  import AuthService from '@/services/auth.service'
  export default {
    data:() => ({
      active: true,
      menu_items: [
        { text: 'Inicio', icon: 'home_work', href: '/admin/home', id: 'home' },
        { text: 'Banners', icon: 'add_photo_alternate', href: '/admin/banners', id: 'banners' },
        { text: 'Sección nosotros', icon: 'question_answer', href: '/admin/about', id: 'about' },
        { text: 'Codigos QR', icon: 'attachment', href: '/admin/qr-codes', id: 'qr' },
        { text: 'Tallas/Colores', icon: 'filter_none', href: '/admin/attributes', id: 'attributes' },
        { text: 'Productos', icon: 'shopping_cart', href: '/admin/products', id: 'produts' },
        { text: 'Categorias de productos', icon: 'dashboard', href: '/admin/product-categories', id: 'categories' },
        { text: 'Posts', icon: 'create', href: '/admin/posts', id: 'posts' },
        { text: 'Politícas del sitio', icon: 'create', href: '/admin/policies', id: 'policies' },
        { text: 'Ordenes', icon: 'credit_card', href: '/admin/orders', id: 'orders' },
        { text: 'Clientes', icon: 'people_alt', href: '/admin/users', id: 'users' }
      ],
      inactive_bottom: false
    }),
    methods: {
      async logout() {
        try {
          this.inactive_bottom = true
          await AuthService.logout()
          await this.$store.dispatch('setUser', {
            user: { name: '' },
            session: false
          })
          localStorage.removeItem('token_tag')
          this.$router.push('/')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

