<template>
  <div>
    <sidebar></sidebar>
    <div class="layout">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
export default {
  components: {
    Sidebar
  }
}
</script>

<style scoped>
.layout {
  padding-left: 280px;
  padding-top: 50px;
  padding-right: 20px;
}
</style>
